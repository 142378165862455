<template>
  <div class="deposit_edit world">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="paddB10">
      <el-breadcrumb-item :to="{ path: '/enterprise/centre/lanzhou/deposit/edit' }" class="bs_text_oo">
        返回上一级<span> | </span>残保金审核
      </el-breadcrumb-item>
      <el-breadcrumb-item class="color66">新增</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content backgroundWhite">
      <h1 class="font18 bold">按比例安置残疾人就业保障金年审申请</h1>
      <ul class="tab flex">
        <li v-for="(n,i) in tabs" :key="i" :class="i === 4?'active':''">{{ n }}</li>
      </ul>
      <div class="container next_three next_four">
        <div class="tip marT50 marB20">
          <p>说明：请上传本单位员工社保缴费证明
          </p>
        </div>
        <div class="form">
          <div class="prompt">注：最多可添加6张</div>
          <el-upload
              action="/api/app-jycy-disabled/uploadImg"
              class="avatar-uploader avatar-uploader_certificate"
              :headers="uploadHeaders"
              list-type="picture-card"
              :file-list="jytjb"
              :on-remove="removeFile"
              :on-success="handleCertificateSuccess"
              accept=".jpg,.png"
              :before-upload="beforeAvatarUpload">
            <img src="~/static/center/img_add.png" alt="" class="block_inline img">
          </el-upload>
        </div>
        <div class="buttons_add">
          <el-button type="primary" class="w170" @click="goRouter('/enterprise/centre/lanzhou/deposit/nextEdit2')">上一步
          </el-button>
          <el-button type="primary" class="w170" :loading="loading" @click="submit">下一步</el-button>
          <div class="tips">
            点击保存，信息会存为草稿，提交前均可编辑，确保信息无误后再提交。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nextEdit2",
  data() {
    return {
      tabs: ['单位信息', '安置残疾人就业信息', '全员工资表', '就业统计表', '社保缴费证明', '医保缴费证明'],
      jytjb: [],
      socialList: [],
      loading:false,
    }
  },
  methods: {
    async info() {
      let res = await this.$api.lanzhouGetComImgsApi({imgType:'0'});
      if (res.data.success) {
        res.data.data.forEach((n,i) =>{
          this.socialList.push(n.imgSrc);
        })
        for (var i = 0; i <= this.socialList.length - 1; i++) {
          const ur = {};
          ur.url = this.socialList[i];
          this.jytjb.push(ur);
        }
      }
    },
    submit() {
      if(typeof this.socialList !== 'object'){
        return
      }
      this.socialList = this.socialList.join(";");
      this.loading = true;
      this.$api.lanzhouSetComImgsApi({imgSrc:this.socialList,imgType:'0'}).then(res => {
        if (res.data.success) {
          this.loading = false;
          this.$message.success('保存成功！');
          this.goRouter('/enterprise/centre/lanzhou/deposit/nextEdit4');
        }else{
          this.loading = false;
        }
      })

    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M
    },
    handleCertificateSuccess(file, fileList) {
      console.log(file)
      this.socialList.push(file.msg);
    },
    removeFile(file, fileList) {
      if (file.response) {
        this.socialList.forEach((item, i) => {
          if (item == file.response.msg) {
            this.socialList.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
          }
        })
      } else {
        this.socialList.forEach((item, i) => {
          if (item == file.url) {
            this.socialList.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
          }
        })
      }
    },
    goRouter(url) {
      this.$router.push(url);
    },
  },
  created() {
    this.info();
  }
}
</script>

<style scoped lang="less">
@import "~assets/lanzhou/enterprise.less";
@import "~assets/lanzhou/deposit.less";
</style>
